<!-- eslint-disable max-len -->
<template>
  <div class="winner-view-content bg-content">
    <RestartButton @click="restartTrivia" backgroundColor="var(--third-color)" fontColor="var(--secondary-color)"
      :text="restartButtonText" class="restart-button-container" />
    <div class="column-container">
      <div class="form">
        <div class="head">
          <img src="../../public/img/images/logo_site.svg" alt="">
          <h2 class="congrats">
            Congratulations, you win!
          </h2>
          <h3 style="margin: 0 0 -30px 0;">
            Scan the code for free shipping on your next <br>order of wild turkey.
          </h3>
        </div>
        <div class="body">
          <QRCode :url="thanksURL" />

          <!-- <p class="bold">
            If you have any issue scanning or loading the page,
            take a photo and show it to one of our Wild Turkey Ambassadors.
          </p>
          <p class="light">
            Wild Turkey® Liqueur. 11% alc./vol. (22 Proof).
            ©2024 Campari America, New York, NY. Please enjoy responsibly.
          </p> -->
        </div>
      </div>
      <div class="background-image-container">
        <img src="../../public/img/images/red_turkey.svg" alt="red turkey" class="background-image" />
      </div>
    </div>

    <div class="footer-container">
      <span>NEVER COMPROMISE. DRINK RESPONSIBLY.</span>
      <p>
        WILD TURKEY® Kentucky Straight Bourbon Whiskey, 50.5% alc./vol. (101 Proof).
        Distilled and Bottled by Wild Turkey Distilling Co., Lawrenceburg,
        KY ©2024 Campari America, New York, NY.
      </p>
    </div>
  </div>
</template>

<script>
import RestartButton from '@/components/RestartButton.vue';
import QRCode from '@/components/QRCode.vue';

export default {
  name: 'WinnerView',
  components: {
    RestartButton,
    QRCode,
  },
  data() {
    return {
      timer: null,
      timeout: 30,
      remaining: 30,
    };
  },
  methods: {
    restartTrivia() {
      this.$router.push('/');
    },
    formatTime(numSeconds) {
      const minutes = Math.floor(numSeconds / 60);
      const seconds = numSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // if (minutes > 0) {
      //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // }
      // return `${seconds}`;
    },
    startTimer() {
      if (this.timer == null) {
        this.resetTimer();
      }
      this.timer = setInterval(() => {
        this.remaining -= 1;
        if (this.remaining <= 0) {
          this.remaining = this.timeout;
          this.resetTimer();
          this.$router.push('/');
        }
      }, 1000);
    },
    resetTimer() {
      if (this.timer == null) {
        return;
      }
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  computed: {
    restartButtonText() {
      // eslint-disable-next-line quotes
      return `Restart`;
      // const { remaining, formatTime } = this;
      // const remainingString = formatTime(remaining);
      // return `Restart ${remainingString}`;
    },
    thanksURL() {
      const eventName = sessionStorage.getItem('eventName');
      let dynamicParam = '';
      if (eventName) {
        dynamicParam = `?event=${encodeURIComponent(eventName)}`;
      }
      const url = `https://sc-ut.me/PRYTPKiwKINswVq${dynamicParam}`;
      return url;
    },
  },
  mounted() {
    // this.startTimer();
  },
  beforeUnmount() {
    // this.resetTimer();
  },
};
</script>

<style lang="scss">
.winner-view-content {
  // display: grid;
  // grid-template-columns: 1fr 500px;
  display: block;
  overflow: hidden;

  .column-container {
    display: grid;
    grid-template-columns: 1fr 500px;
    height: 100dvh;
  }

  .restart-button-container {
    position: fixed;
    right: 20px;
    top: 20px;
    // display: none;
  }

  .background-image-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;

    img {
      height: calc(100dvh - 35px);
    }
  }

  .form {
    .body {
      gap: 0;
    }

    .head {
      h2 {
        margin: 50px 0;
        color: var(--third-color);
        font-family: 'Flama condensed medium';
        font-size: 40px;
      }

      h2.congrats {
        color: var(--secondary-color) !important;
        margin-bottom: 5px;
        font-size: 55px;
      }

      h3 {
        font-size: 35px;
        font-family: 'Flama condensed light';
      }
    }
  }

  p.light {
    font-family: 'Futura Std Light' !important;
  }

  .el-message-box__title {
    padding-left: 10px;

    span {
      font-family: "Futura Std Book", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 48px;
      text-transform: uppercase;
    }
  }

  .el-message-box__message {
    text-align: center;

    p {
      font-family: "Futura Std Light", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .el-message-box__btns {
    .el-button {
      font-family: "Futura Std Bold", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FA4700;
      background: var(--third-color);
      box-shadow: 0px 6.20541px 12.4108px rgba(183, 53, 2, 0.88);
      border-radius: 54px;
      border: 1px solid;
    }
  }

  h3 {
    font-family: 'Flama condensed medium';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: var(--third-color);

    b {
      font-weight: bold;
    }
  }

  h2 {
    margin-bottom: 0;
    padding: 0;
  }

  h3 {
    margin: 0;
    padding: 0;
  }

  .body {
    p {
      max-width: 499px;
      font-family: 'Futura Std Book';
      font-style: normal;
      font-weight: 650;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: var(--third-color);
    }
  }

  .footer-container {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 52px;

    font-family: 'Flama condensed light';
    font-weight: normal;
    color: var(--third-color);
    font-style: normal;
    font-weight: 400;
    font-size: 19px;

    p {
      margin: 0;

      font-family: 'Flama condensed light';
      font-weight: normal;
    }
  }
}
</style>
