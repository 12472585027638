<!-- eslint-disable max-len -->
<template>
  <div class="form-view-content bg-content animation">
    <div class="form">
      <div>
        <div class="skip">
          <!-- <el-button @click="skip()">
            <div>
              <el-icon>
                <CaretRight />
              </el-icon>
              <el-icon class="icon-2">
                <CaretRight />
              </el-icon>
            </div>
            skip
          </el-button> -->
        </div>
        <div class="head">
          <img src="../../public/img/images/logo_site.svg" alt="" />
          <h2>JOIN THE WILD TURKEY COMMUNITY</h2>
          <!-- <p>
            Don’t miss our exclusive email offers, including future events, promotions and news.
          </p> -->
        </div>
        <div class="body">
          <form-component></form-component>
        </div>
      </div>
      <div class="background-image-container">
        <img src="../../public/img/images/red_turkey.svg" alt="red turkey" class="background-image" />
      </div>
      <div class="footer-container">
        <span>NEVER COMPROMISE. DRINK RESPONSIBLY.</span>
        <p>
          WILD TURKEY® Kentucky Straight Bourbon Whiskey, 50.5% alc./vol. (101 Proof).
          Distilled and Bottled by Wild Turkey Distilling Co., Lawrenceburg,
          KY ©2024 Campari America, New York, NY.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import FormComponent from '@/components/FormComponent.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { CaretRight } from '@element-plus/icons-vue';
// import IndexedDB from '../../utils/indexedDB';

export default {
  name: 'FormView',
  components: { FormComponent },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(['setStartTime']),
    skip() {
      // IndexedDB.addAnimationTop();
      this.$store.commit('setStartTime', new Date());
      setTimeout(() => {
        this.$router.push('/trivia');
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.form-view-content {

  .background-image-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 450px;
    padding-top: 35px;
    height: calc(100dvh - 35px);
    overflow: hidden;

    .head {
      padding: 0;

      img {
        height: 136px;
      }

      h2 {
        margin-bottom: 20px;
      }
    }

    .skip {
      .el-button {
        width: 98px;
        height: 42px;
        background: var(--third-color);
        border-radius: 58px;
        font-family: "Futura Std Bold", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: right;
        text-transform: uppercase;
        color: var(--secondary-color);
        position: absolute;
        right: 40px;
        top: 20px;

        &>span {
          gap: 10px;
        }

        .icon-2 {
          margin-left: -23px;
        }

        &:hover {
          background: rgba(255, 242, 237, 0.76);
        }
      }
    }

    .body {
      margin: auto;

      .el-checkbox {
        .el-checkbox__inner {
          border: 1.63125px solid var(--secondary-color);
          width: 40px;
          height: 40px;
          background: var(--third-color);

          &::after {
            height: 25px;
            left: 16px;
            top: 3px;
            border: 4px solid #000;
            border-left: 0;
            border-top: 0;
          }
        }
      }
    }

    img.background-image {
      height: calc(100dvh - 35px);
    }

    .footer {
      bottom: 20px;
    }
  }

  .footer-container {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 52px;

    font-family: 'Flama condensed light';
    font-weight: normal;
    color: var(--third-color);
    font-style: normal;
    font-weight: 400;
    font-size: 19px;

    p {
      margin: 0;

      font-family: 'Flama condensed light';
      font-weight: normal;
    }
  }
}
</style>
