<template>
  <div class="age-view-content bg-content">
    <div class="form">
      <div class="head">
        <img src="../../public/img/images/logo_app.svg" alt="">
        <h2>
          are you of legal drinking age?
        </h2>
      </div>
      <div class="body">
        <d-o-b-component @click-button="onSubmit()" v-model="dob" :status="status">
        </d-o-b-component>
        <el-button class="trivia_button" @click="onSubmit()">
          Next
        </el-button>
      </div>
      <div class="footer-container">
        <span>NEVER COMPROMISE. DRINK RESPONSIBLY.</span>
        <p>
          WILD TURKEY® Kentucky Straight Bourbon Whiskey, 50.5% alc./vol. (101 Proof).
          Distilled and Bottled by Wild Turkey Distilling Co., Lawrenceburg,
          KY ©2024 Campari America, New York, NY.
        </p>
      </div>
    </div>
    <div class="background-image-container">
      <img src="../../public/img/images/red_turkey.svg" alt="red turkey" class="background-image">
    </div>
  </div>
</template>

<script>
import DOBComponent from '@/components/DOBComponent.vue';
import { mapMutations } from 'vuex';
import IndexedDB from '../../utils/indexedDB';

export default {
  name: 'AgeView',
  components: { DOBComponent },
  data() {
    return {
      dob: '',
      status: false,
      arrEntries: [],
      entry: {
        completionReason: '',
        correct: 0,
        dob: '',
        email: '',
        firstName: '',
        incorrect: 0,
        lastName: '',
        log_entries: [],
        optIn: false,
        qrShown: 0,
        questionPresented: 0,
        questionsAnswered: 0,
        retries: 0,
        timeElapsed: 0,
        url: '',
        uuid: '',
        zipCode: '',
      },
    };
  },
  watch: {
    dob() {
      this.status = false;
    },
  },
  methods: {
    ...mapMutations(['setUUID']),
    onSubmit() {
      this.status = true;
      setTimeout(() => {
        this.status = false;
      }, '1000');
    },
    async next(isValid) {
      if (isValid === true && this.dob !== '') {
        const date = new Date();
        const dobSeparated = this.dob.split('-');
        const year = parseInt(dobSeparated[0], 10);
        const month = parseInt(dobSeparated[1], 10) - 1;
        const day = parseInt(dobSeparated[2], 10);
        const dob = new Date(year, month, day);
        const diff = date.getTime() - dob.getTime();
        const age = diff / (1000 * 60 * 60 * 24 * 365.25);
        console.log(age, ' <21: ', age < 21);
        if (age < 21) {
          this.$alert('You are not of a legal drinking age, so you can\'t play the Wild Turkey trivia game.', 'Oops!', {
            confirmButtonText: 'OK',
          });
        } else if (age > 100) {
          this.$alert('You are not of a legal drinking age, so you can\'t play the Wild Turkey trivia game.', 'Oops!', {
            confirmButtonText: 'OK',
          });
        } else {
          this.arrEntries = await IndexedDB.getIndexedDB();
          this.entry.uuid = IndexedDB.generateUUID();
          this.entry.dob = this.dob;
          this.arrEntries.push(this.entry);
          const indexed = process.env.VUE_APP_INDEXED_DB;
          const response = await IndexedDB.insertIndexedDB({
            data: this.arrEntries,
            name: indexed,
            trivia: true,
          });
          this.$store.commit('setUUID', this.entry.uuid);
          if (response) {
            this.$router.push('/form');
          }
        }
      }
    },
  },
  async mounted() {
    IndexedDB.emitter.on('dobNext', await this.next);
  },
};
</script>
<style lang="scss">
.age-view-content {
  display: grid;
  grid-template-columns: 1fr 500px;
  overflow: hidden;

  .form {
    img {
      height: 143px;
    }

    .head {
      h2 {
        font-size: 46px;
      }
    }
  }

  .background-image-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;

    img {
      // height: calc(100dvh - 35px);
      object-position: contain;
      // padding-top: 35px;
    }
  }

}

.footer-container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 52px;

  font-family: 'Flama condensed light';
  font-weight: normal;
  color: var(--third-color);
  font-style: normal;
  font-weight: 400;
  font-size: 19px;

  p {
    margin: 0;

    font-family: 'Flama condensed light';
    font-weight: normal;
  }
}
</style>
