<!-- eslint-disable max-len -->
<template>
  <div class="DOB-content">
    <el-form ref="formDOB" :rules="rules" :model="formDOB" label-width="120px" label-position="top">
      <el-form-item label="Month" prop="month">
        <el-input type="text" inputmode="numeric" pattern="\d*" ref="month" maxlength="2" placeholder="MM"
          v-model="formDOB.month">

        </el-input>
      </el-form-item>
      <el-form-item label="Day" prop="day">
        <el-input type="text" inputmode="numeric" pattern="\d*" ref="day" maxlength="2" placeholder="DD"
          v-model="formDOB.day">
        </el-input>
      </el-form-item>
      <el-form-item label="Year" prop="year">
        <el-input type="text" inputmode="numeric" pattern="\d*" ref="year" maxlength="4" placeholder="YYYY"
          v-model="formDOB.year" @keyup.enter="$emit('click-button')">
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import IndexedDB from '../../utils/indexedDB';

export default {
  name: 'DOBComponent',
  props: ['modelValue', 'status'],
  data() {
    return {
      formDOB: {
        month: '',
        day: '',
        year: '',
      },
      days: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
      ],
      months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
      rules: {
        month: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
            type: 'string',
          },
          { pattern: /^\d+$/, message: 'Enter numeric digits only' },
          { min: 2, message: 'Minimum 2 characters', trigger: 'blur' },
        ],
        day: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
            type: 'string',
          },
          { pattern: /^\d+$/, message: 'Enter numeric digits only' },
          { min: 2, message: 'Minimum 2 characters', trigger: 'blur' },
        ],
        year: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
            type: 'string',
          },
          { pattern: /^\d+$/, message: 'Enter numeric digits only' },
          { min: 4, message: 'Minimum 4 characters', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    async status(val) {
      if (val === true) {
        await this.onSubmit();
      }
    },
    formDOB: {
      handler() {
        // console.log(newValue);
        this.createdDate();
      },
      deep: true,
    },
    'formDOB.month': {
      handler(newValue) {
        if (typeof newValue !== 'string') {
          return;
        }
        if (!/^\d+$/.test(newValue)) {
          this.formDOB.month = '';
        }
        if (newValue.length === 2) {
          this.$refs.day.focus();
        }
      },
    },
    'formDOB.day': {
      handler(newValue) {
        if (typeof newValue !== 'string') {
          return;
        }
        if (!/^\d+$/.test(newValue)) {
          this.formDOB.day = '';
        }
        if (newValue.length === 2) {
          this.$refs.year.focus();
        }
      },
    },
    'formDOB.year': {
      handler(newValue) {
        if (typeof newValue !== 'string') {
          return;
        }
        if (!/^\d+$/.test(newValue)) {
          this.formDOB.year = '';
        }
        if (newValue.length === 0) {
          this.$refs.day.focus();
        }
        // if (newValue.length === 2) {
        //   this.$refs.day.focus();
        // }
      },
    },
  },
  mounted() {
    this.$refs.month.focus();
  },
  methods: {
    createdDate() {
      const day = this.formDOB.day.padStart(2, '0');
      const month = this.formDOB.month.padStart(2, '0');
      if (day !== '00' && !this.days.includes(day)) {
        this.formDOB.day = '';
      }
      if (month !== '00' && !this.months.includes(month)) {
        this.formDOB.month = '';
      }
      if (this.formDOB.year === '0000' && this.formDOB.month < 1900) {
        this.formDOB.year = '';
      }
      const date = `${this.formDOB.year ?? 'YYYY'}-${this.formDOB.month.padStart(2, '0') ?? 'MM'}-${this.formDOB.day.padStart(2, '0') ?? 'DD'}`;
      if (this.formDOB.month.length === 2 && this.formDOB.day.length === 2
        && this.formDOB.year.length === 4) {
        if (IndexedDB.validateDate(date)) {
          this.$emit('update:modelValue', date);
        } else {
          this.formDOB.year = '';
          this.formDOB.day = '';
          this.$alert('Enter a valid date', 'Sorry!', {
            confirmButtonText: 'OK',
          });
        }
      }
    },
    validateForm(ref) {
      return new Promise((resolve) => {
        this.$refs[ref].validate((valid) => {
          if (valid) {
            resolve(true);
          }
          resolve(false);
        });
      });
    },
    async onSubmit() {
      const isValid = await this.validateForm('formDOB');
      IndexedDB.emitter.emit('dobNext', isValid);
    },
  },
};
</script>

<style lang="scss">
.DOB-content {
  margin: auto;
  padding-top: 5px;
  padding-bottom: 80px;

  .el-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 28px;

    .el-form-item__label {
      font-family: 'Flama condensed medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      text-transform: uppercase;
      color: #FDFBFB;
    }

    .el-input {
      .el-input__wrapper {
        border-radius: 0 !important;
        color: var(--secondary-color);
        background: var(--third-color);
        // border: 2.15926px solid var(--secondary-color);

        // &.is-focus {
        // background: rgba(255, 242, 237, 0.76);
        // border: 2.15926px solid var(--third-color);
        // }

        .el-input__inner {
          color: var(--secondary-color);
          width: 111.69px;
          height: 93.07px;
          box-sizing: border-box;
          font-family: 'Flama condensed medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          text-align: center;
          outline: none !important;

          &::placeholder {
            color: var(--secondary-color);
          }
        }

        // &.is-focus {
        //   background: rgba(255, 242, 237, 0.76);
        //   border: 2.15926px solid var(--third-color);
        // }
      }
    }

    .el-form-item__error {
      color: var(--third-color);
      padding-top: 8px;
      font-size: 16px;
      white-space: pre;
    }

    .el-form-item.is-required:not(.is-no-asterisk).asterisk-left>.el-form-item__label:before {
      content: "*";
      color: var(--third-color);
      margin-right: 4px;
    }
  }
}
</style>
