<!-- eslint-disable max-len -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div>
    <!-- <span class="title_orange">Select the correct answer</span> -->
    <h1 class="title">{{ questionData.question }}</h1>

    <figure class="container-cards">
      <section v-for="answer in questionData.answers" class="card" :class="applyClass ? currentClass(answer) : ''"
        @click="handleAnswer(answer)" :key="answer.letter">
        <div class="img_answer">
          <img :src="`/img/images/questions/${answer.attachment}`" :alt="answer.attachment" />
        </div>
        <div class="answer_footer">
          <span class="letter">{{ letters[answer.order - 1] }}</span>
          <span class="text">
            {{ answer.answer }}
            <div class="correct">
              <img src="../../public/img/images/check.svg" alt="check" />
            </div>
            <div class="wrong">
              <img src="../../public/img/images/x.svg" alt="x" />
            </div>
          </span>
        </div>
      </section>
    </figure>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IndexedDB from '../../utils/indexedDB';

export default {
  props: {
    questionData: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    retries: {
      type: Number,
      default: 0,
    },
    retriesGlobal: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    applyClass: false,
    letters: [
      'A.',
      'B.',
      'C.',
      'D.',
    ],
    selectedAnswer: {},
  }),
  watch: {
    questionData(newQuestion, oldQuestion) {
      if (newQuestion.question === oldQuestion.question) return;

      this.applyClass = false;

      this.updateQuestionsPresented();
    },
    disabled(newQuestion, oldQuestion) {
      if (newQuestion === oldQuestion) return;

      this.applyClass = newQuestion;
    },
  },
  computed: {
    ...mapState(['uuid']),
  },
  methods: {
    async handleAnswer(answer) {
      if (this.disabled) return false;

      this.selectedAnswer = answer;
      this.applyClass = true;

      if (answer.isCorrect || this.retries > 0) {
        this.$emit('active-modal', {
          copy: answer.isCorrect || this.retries === 1 ? answer.copy : 'Try again!',
          disabled: !answer.isCorrect,
          isCorrect: answer.isCorrect,
        });
      } else if (this.retries === 0) {
        this.$emit('active-retry-modal');
      }

      if (!answer.isCorrect && this.retries < 1) {
        return false;
      }

      // Process database
      const indexed = process.env.VUE_APP_INDEXED_DB;
      const AllData = await IndexedDB.getIndexedDB(indexed);
      const {
        completionReason,
        correct,
        incorrect,
        log_entries: logEntries,
        questionsAnswered,
      } = AllData.filter((word) => word.uuid === this.uuid)[0] || {};

      if (completionReason === 'finished') return false;

      const {
        id: correctAnswerId,
        answer: correctAnswerText,
      } = this.questionData.answers.filter((answerData) => answerData.isCorrect)[0] || {};

      const newLogEntry = {
        answerId: answer.id,
        answerText: answer.answer,
        correctAnswerId,
        correctAnswerText,
        eventName: 'Answer question',
        eventType: 'answer_question',
        questionId: this.questionData.id,
        questionText: this.questionData.question,
        retries: this.retries,
      };

      await IndexedDB.updateEntries({
        name: indexed,
        user: this.uuid,
        data: {
          correct: answer.isCorrect ? correct + 1 : correct,
          incorrect: (!answer.isCorrect) ? incorrect + 1 : incorrect,
          log_entries: [
            ...logEntries,
            newLogEntry,
          ],
          questionsAnswered: questionsAnswered + 1,
          retries: this.retriesGlobal,
        },
      });

      const algo2 = await IndexedDB.getIndexedDB(indexed);
      console.log(algo2);

      return '';
    },
    currentClass(answer) {
      if (answer.isCorrect) {
        if (this.selectedAnswer.id === answer.id || this.retries > 0) {
          return 'card-correct';
        }

        return 'card-blur';
      }

      if (!answer.isCorrect) {
        if (this.selectedAnswer.id === answer.id) {
          return 'card-wrong';
        }

        return 'card-blur';
      }
      return '';
    },
    async updateQuestionsPresented() {
      // Process database
      const indexed = process.env.VUE_APP_INDEXED_DB;
      const AllData = await IndexedDB.getIndexedDB(indexed);
      const {
        questionPresented,
      } = AllData.filter((word) => word.uuid === this.uuid)[0];

      await IndexedDB.updateEntries({
        name: indexed,
        user: this.uuid,
        data: {
          questionPresented: questionPresented + 1,
          retries: this.retriesGlobal,
        },
      });

      const algo2 = await IndexedDB.getIndexedDB(indexed);
      console.log(algo2);
    },
  },
  mounted() {
    this.updateQuestionsPresented();
  },
};
</script>

<style lang="scss" scoped>
.orange {
  color: var(--secondary-color);
}

.container-cards {
  --answer-footer-min-height: 56px;
  // background-color: rgba(green, 0.4);
}

.card {
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;

  border: 1px solid var(--primary-color);
  max-width: 206px;

  & div span img {
    display: none;
  }

  .img_answer {
    height: 300px;
    width: 206px;
    display: grid;
    align-content: end;
    justify-content: center;

    img {
      height: auto;
      max-height: 300px;
    }
  }

  &.card-correct {
    .correct {
      position: absolute;
      top: -20px;
      right: -20px;
      background-color: var(--correct-color);
      width: 36px;
      height: 36px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;

      img {
        display: block;
      }
    }
  }

  &.card-wrong {
    .wrong {
      position: absolute;
      top: -20px;
      right: -20px;
      background-color: #D9D9D9;
      width: 36px;
      height: 36px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;

      img {
        display: block;
      }
    }
  }
}

.card-correct {
  height: 100%;
  border: 1px solid var(--primary-color);

  .answer_footer {

    .letter {
      background-color: var(--correct-color);
    }

    .text {
      background-color: #E3FFE2;
    }
  }
}

.card-wrong {

  .img_answer>img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    z-index: 0;
  }

  .answer_footer {
    .letter {
      background-color: #827E7E;
    }

    .text {
      background-color: #FBFBFB;
    }
  }
}

.card-blur {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255 255 255 / 47%);
    z-index: 0;
  }
}

.title {
  font-family: 'Futura Std Medium Condensed';
  line-height: 34px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 36px;
  margin-top: 50px;
  margin-bottom: 60px;
}

.title_orange {
  @extend .orange;
  font-size: 20px;
}

h1 {
  color: #000000;
}

figure {
  display: flex;
  justify-content: center;
  column-gap: 50px;

  @media (orientation: portrait) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  place-items: center;
  row-gap: 20px;
}

.answer_footer {
  // height: 100%;
  // --answer-footer-min-height: 56px;
  display: grid;
  grid-template-columns: 44px 1fr;
  min-height: 56px;
  // min-height: var(--answer-footer-min-height);
  max-width: 240px;
  border-top: 1px solid var(--primary-color);

  .letter {
    // height: 100%;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--third-color);
    font-weight: 700;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 400;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
