<!-- eslint-disable max-len -->
<template>
  <div class="qr-container">
    <!-- {{ url }} -->
    <qrcode-vue :value="url" :size="250" render-as="svg" foreground='var(--third-color)' background='#0000' level="H"
      class="qr-image" :margin="0"></qrcode-vue>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    QrcodeVue,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
.qr-container {
  // max-width: 250px;
  margin-top: 40px;
  width: 250px;
  height: 250px;
  // background: red;
}
</style>
