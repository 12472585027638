<template>
  <div class="intro-view bg-content">
    <div class="logo-container">
      <logo-component />
      <h2>TRIVIA</h2>
    </div>
    <div class="main-content">
      <h1>
        Let’s see how much you know about wild turkey.
        <br>
      </h1>
      <h2>play our visual trivia game to RECEIVE a special offer from us.</h2>
      <button class="trivia_button" @click="handleStartButton">START</button>
    </div>
    <div class="footer-container">
      <span>NEVER COMPROMISE. DRINK RESPONSIBLY.</span>
      <p>
        WILD TURKEY® Kentucky Straight Bourbon Whiskey, 50.5% alc./vol. (101 Proof).
        Distilled and Bottled by Wild Turkey Distilling Co., Lawrenceburg,
        KY ©2024 Campari America, New York, NY.
      </p>
    </div>
  </div>
</template>

<script>
// import { toRaw } from 'vue';
// import animo from 'animo-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'animate.css';
import LogoComponent from '@/components/IntroView/LogoComponent.vue';
// import AperolSpritz from '@/components/IntroView/AperolSpritz.vue';
// import TriviaGame from '@/components/IntroView/TriviaGame.vue';

export default {
  components: {
    LogoComponent,
    // AperolSpritz,
    // TriviaGame,
  },
  data() {
    return {
      animate: true,
    };
  },
  methods: {
    handleStartButton() {
      this.$router.push({ path: '/age-gate' });
    },
    async sleep(duration = 1000) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(true), duration);
      });
    },
    async waitAnimation(target, newClass = null) {
      return new Promise((resolve) => {
        function onAnimationEnd() {
          // Remove the event listener
          // console.log('animation complete - ', newClass);
          target.removeEventListener('animationend', onAnimationEnd);
          // Call the callback function
          resolve(target);
        }

        // Add a listener for the animationend event
        target.addEventListener('animationend', onAnimationEnd);
        if (newClass != null) {
          target.classList.add(newClass);
        }
      });
    },
    async runAnimation() {
      const { animate, sleep, waitAnimation } = this;
      if (!animate) {
        return;
      }

      // Elements
      const logo = document.querySelector('.intro-view .logo');
      const logoContainer = document.querySelector('.intro-view .aperol-logo-container');
      const footerContainer = document.querySelector('.intro-view .footer-container');
      const mainContent = document.querySelector('.intro-view .main-content');

      // console.log('Starting animation cycle....');

      await Promise.all([
        waitAnimation(logo, 'shrink'),
        waitAnimation(logoContainer, 'animate'),
        waitAnimation(footerContainer, 'animate'),
      ]);
      logoContainer.classList.remove('shrink-aperol-reverse');
      await waitAnimation(logoContainer, 'shrink-aperol');

      // Show main content
      mainContent.classList.add('animate__animated');
      await waitAnimation(mainContent, 'animate__fadeInUp');

      await sleep(5000);

      // Step 2 - Enter Aperol Spritz

      // Reverse Logo

      mainContent.classList.remove('animate__fadeInUp');
      mainContent.classList.add('animate__fast');
      await waitAnimation(mainContent, 'animate__fadeOutDown');
      mainContent.classList.remove('animate__fast');
      logoContainer.classList.remove('shrink-aperol');
      await waitAnimation(logoContainer, 'shrink-aperol-reverse');

      logoContainer.classList.remove('animate');
      logo.classList.remove('shrink');
      footerContainer.classList.remove('animate');
      footerContainer.classList.add('animate__animated');

      await Promise.all([
        waitAnimation(logoContainer, 'animate-reverse'),
        waitAnimation(logo, 'shrink-reverse'),
        waitAnimation(footerContainer, 'animate__fadeOutDown'),
      ]);
      footerContainer.classList.remove('animate', 'animate__animated', 'animate__fadeOutDown');

      // Reset reverse classes
      logo.classList.remove('shrink-reverse', 'shrink');
      mainContent.classList
        .remove('animate__animated', 'animate__fadeOutDown', 'animate__fadeInUp');
      logoContainer.classList.remove('animate', 'animate-reverse', 'shrink-aperol');
      await sleep();
    },
  },
  async mounted() {
    const { runAnimation, sleep } = this;
    this.animate = false;

    while (this.animate) {
      // console.log('new iteration....');
      try {
        // eslint-disable-next-line no-await-in-loop
        await runAnimation();
        // break;
      } catch (e) {
        console.error(e.message);
        console.error('EXPLODED');
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(2000);
    }
  },
  beforeUnmount() {
    // console.log('Stopping animation...');
    this.animate = false;
  },
};

</script>

<style lang="scss" scoped>
.intro-view {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 35px;

  .hide {
    opacity: 0;
  }

  .logo-container {
    h2 {
      font-size: 112px;
      margin: 0;
      color: var(--secondary-color);
      font-family: 'Flama condensed book';
    }

    .logo {
      position: relative;

      &.shrink {
        animation: shrink 1s forwards;

        &.shrink-reverse {
          // opacity: 0.5;
          animation: shrink-reverse 1s forwards;
          // animation-play-state: running;
        }
      }

      &.shrink-reverse {
        animation: shrink-reverse 1s forwards;
      }
    }
  }

  .aperol-logo-container {
    position: fixed;
    transform: translateX(-4%);

    .aperol-spritz {
      position: relative;
      // Final position
      transform: translate(-2000%, 20%);
    }

    .trivia-game {
      position: relative;
      // transform: translate(13%, -43%);
      transform: translate(2000%, -43%);
    }

    &.animate {
      .aperol-spritz {
        animation: enter-left 2.5s forwards;
      }

      .trivia-game {
        animation: enter-right 2.5s forwards;
        // transform: translate(13%, -43%);
      }
    }

    &.animate-reverse {
      .aperol-spritz {
        animation: exit-left 1.5s forwards;
      }

      .trivia-game {
        animation: exit-right 1.5s forwards;
      }
    }

    &.shrink-aperol {
      animation: shrink-aperol 1s forwards;
    }

    &.shrink-aperol-reverse {
      animation: shrink-aperol-reverse 1s forwards;
    }
  }

  .footer-container {
    position: fixed;
    bottom: 52px;
    // bottom: -1000px;

    font-family: 'Flama condensed light';
    font-weight: normal;
    color: var(--third-color);
    font-style: normal;
    font-weight: 400;
    font-size: 19px;

    // &.animate {
    //   animation: enter-footer 2.5s forwards;
    // }

    p {
      margin: 0;

      font-family: 'Flama condensed light';
      font-weight: normal;
    }
  }

  .main-content {
    // position: fixed;
    bottom: 155px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: 'Flama condensed light';
      font-weight: normal;
      color: var(--third-color);
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    h2 {
      font-family: 'Flama condensed medium';
      font-weight: normal;
      color: var(--third-color);
      font-size: 30px;
      line-height: 29px;
      text-transform: uppercase;
    }

    button {
      margin-top: 20px;
    }
  }

  @keyframes shrink {
    0% {
      scale: 1;
      top: -285px;
    }

    100% {
      scale: 1;
      top: -285px;
    }
  }

  @keyframes shrink-reverse {
    0% {
      scale: 1;
      top: -285px;
    }

    100% {
      scale: 1;
      top: 0;
    }
  }

  @keyframes enter-left {
    0% {
      transform: translate(-2000%, 20%);
    }

    100% {
      transform: translate(0%, 20%);
    }
  }

  @keyframes exit-left {
    0% {
      transform: translate(0%, 20%);
    }

    100% {
      transform: translate(-2000%, 20%);
    }
  }

  @keyframes enter-right {
    0% {
      transform: translate(2000%, -43%);
    }

    100% {
      transform: translate(21%, -47%);
    }
  }

  @keyframes exit-right {
    0% {
      transform: translate(21%, -47%);
    }

    100% {
      transform: translate(2000%, -43%);
    }
  }

  @keyframes shrink-aperol {
    0% {
      scale: 1;
      top: 85px;
    }

    100% {
      scale: 0.64;
      top: calc(100vh - 88%);
    }
  }

  @keyframes shrink-aperol-reverse {
    0% {
      scale: 0.64;
      top: calc(100vh - 88%);
    }

    100% {
      scale: 1;
      top: 85px;
    }
  }

  @media only screen and (min-height: 700px) and (max-height: 900px) and (orientation: landscape) {
    @keyframes shrink-aperol {
      0% {
        scale: 1;
        top: 85px;
      }

      100% {
        scale: 0.64;
        top: -25px;
      }
    }
  }

  @media only screen and (min-height: 700px) and (max-height: 900px) and (orientation: landscape) {
    @keyframes shrink-aperol-reverse {
      0% {
        scale: 0.64;
        top: -25px;
      }

      100% {
        scale: 1;
        top: 85px;
      }
    }
  }

  @keyframes enter-footer {
    0% {
      bottom: -1000px;
    }

    100% {
      bottom: 52px;
    }
  }

  @keyframes exit-footer {
    0% {
      bottom: 52px;
    }

    100% {
      bottom: -1000px;
    }
  }

}
</style>
