import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://ae8fc11c014ee6f2a61fd1d99415f766@o4507059562414080.ingest.us.sentry.io/4507579176058880',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost:8080', /^https:\/\/wild-turkey-trivia.debrain\.cloud/, /^https:\/\/wild-turkey-trivia.debrain\.cloud\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .mount('#app');
